export const getUrl = (url) => {
    try {
        if (!url) {
            return '#';
        }
        let formattedUrl = url;
        let data = '';
        if (url.toString().startsWith('/')) {
            formattedUrl = url.substring(1); // remove duplicate slash if there is one.
        }
        if (formattedUrl.includes('http') || formattedUrl.startsWith('tel:')) {
            data = formattedUrl;
        }
        if (
            !formattedUrl.includes('http') &&
            formattedUrl !== '/' &&
            !formattedUrl.startsWith('tel:')
        ) {
            data = '/' + formattedUrl;
        }
        if (!formattedUrl.includes('http') && formattedUrl === '/') {
            data = '/';
        }
        return data;
    } catch (error) {
        console.log(error);
    }
};
