import { useEffect, useState } from 'react';

const useOs = ({ operating_system }) => {
    const [osMatch, setOsMatch] = useState(false);
    useEffect(() => {
        if (operating_system) {
            const deviceType = navigator.userAgent;
            const windows = /Windows/;
            const ios = /Macintosh|iPhone|iPad|iPod/;
            const android = /Android/;
            if (windows.test(deviceType) && operating_system === 'windows') {
                setOsMatch(true);
            }
            if (ios.test(deviceType) && operating_system === 'ios') {
                setOsMatch(true);
            }
            if (android.test(deviceType) && operating_system === 'android') {
                setOsMatch(true);
            }
        }
    }, []);
    return osMatch;
};
export default useOs;

/*

If operating system is filled in and it matches the operating system for the browser 
then show on mobile devices otherwise hide it.

*/
