export const navTitles = arg => {
	switch (arg) {
		case 'Patient Resources':
			return 'Patient</br>Resources';
		case 'Clinical Research & Trials':
			return 'Clinical Research<br>& Trials';
		case 'Patient Support Center':
			return 'Patient Support<br>Center';
		default:
			return arg;
	}
};
