import { transformImageUrl } from '@kontent-ai/delivery-sdk';
import { DeliveryError } from '@kontent-ai/delivery-sdk';
import { getRichText } from './getRichText';
import { getButton } from './getButton';
import { isBlank } from './isBlank';

const getImageBackgroundColor = (color) => {
    try {
        if (!color) {
            return null;
        }
        if (color === 'white' || color === 'grey-5') {
            return color;
        }
        return `${color}-light`;
    } catch (error) {
        if (error instanceof DeliveryError) {
            console.error(error.message, error.errorCode);
            return null;
        } else {
            console.error(error);
            return null;
        }
    }
};

export const kontentImageLoader = ({ src, width, quality }) => {
    const builder = transformImageUrl(src)
        .withWidth(width)
        .withQuality(quality || 100)
        .withAutomaticFormat();

    return builder.getUrl();
};

export const getImageWidth = (layout, image, breakpoint) => {
    if (layout === 'fill') {
        return null;
    } else {
        return image[breakpoint]?.width;
    }
};

export const getImageHeight = (layout, image, breakpoint) => {
    if (layout === 'fill') {
        return null;
    } else {
        return image[breakpoint]?.height;
    }
};

export const getImagelayout = (layout, image) => {
    if ((!layout && !image.native) || layout === 'responsive') {
        return 'responsive';
    }
    if (layout === 'fill') {
        return 'fill';
    }
    if (image.native || layout === 'intrinsic') {
        return 'intrinsic';
    }
};

export const getMedia = (media, internal_links) => {
    try {
        const type = media?.system?.type || null;
        switch (type) {
            case 'image':
                return !!media?.elements?.desktop?.value?.length
                    ? {
                          desktop: {
                              src:
                                  media?.elements?.desktop?.value[0]?.url ||
                                  null,
                              width:
                                  media?.elements?.desktop?.value[0]?.width ||
                                  media?.elements?.svg_width?.value ||
                                  null,
                              height:
                                  media?.elements?.desktop?.value[0]?.height ||
                                  media?.elements?.svg_height?.value ||
                                  null,
                          },
                          tablet: {
                              src:
                                  media?.elements?.tablet?.value[0]?.url ||
                                  media?.elements?.desktop?.value[0]?.url ||
                                  null,
                              width:
                                  media?.elements?.tablet?.value[0]?.width ||
                                  media?.elements?.desktop?.value[0]?.width ||
                                  media?.elements?.svg_tablet_width?.value ||
                                  media?.elements?.svg_width?.value ||
                                  null,
                              height:
                                  media?.elements?.tablet?.value[0]?.height ||
                                  media?.elements?.desktop?.value[0]?.height ||
                                  media?.elements?.svg_tablet_height?.value ||
                                  media?.elements?.svg_height?.value ||
                                  null,
                          },
                          mobile: {
                              src:
                                  media?.elements?.mobile?.value[0]?.url ||
                                  media?.elements?.desktop?.value[0]?.url ||
                                  null,
                              width:
                                  media?.elements?.mobile?.value[0]?.width ||
                                  media?.elements?.desktop?.value[0]?.width ||
                                  media?.elements?.svg_mobile_width?.value ||
                                  media?.elements?.svg_width?.value ||
                                  null,
                              height:
                                  media?.elements?.mobile?.value[0]?.height ||
                                  media?.elements?.desktop?.value[0]?.height ||
                                  media?.elements?.svg_mobile_height?.value ||
                                  media?.elements?.svg_height?.value ||
                                  null,
                          },
                          native:
                              !!media?.elements?.native?.value?.length || null,
                          bg_color:
                              getImageBackgroundColor(
                                  media?.elements?.bg_color?.value[0]?.codename.replace(
                                      '_',
                                      '-'
                                  )
                              ) || null,
                          alt: media?.elements?.alt?.value || null,
                          max_width: media?.elements?.max_width?.value || null,
                          caption:
                              isBlank(media?.elements?.caption_v2) ||
                              media?.elements?.caption?.value ||
                              null,
                          caption_size:
                              media?.elements?.caption_size?.value[0]
                                  ?.codename || 'default',
                          caption_alignment:
                              media?.elements?.caption_alignment?.value[0]
                                  ?.codename || null,
                          center_alignment:
                              media?.elements?.center_alignment.value[0]
                                  ?.codename || null,
                          elevation:
                              media?.elements?.elevation?.value[0]?.codename ||
                              null,
                          object_position:
                              media?.elements?.object_position?.value[0]
                                  ?.name || null,
                          object_fit:
                              media?.elements?.object_fit?.value[0]?.codename ||
                              null,
                          type,
                      }
                    : null;
            case 'icon':
                return {
                    icon: media?.elements?.icon.value,
                    color: media?.elements?.icon_color.value[0]?.codename,
                    primary:
                        media?.elements?.icon_primary_color?.value[0]?.codename.replace(
                            '_',
                            '-'
                        ) || null,
                    secondary:
                        media?.elements?.icon_secondary_color?.value[0]?.codename.replace(
                            '_',
                            '-'
                        ) || null,
                    type,
                };
            case 'video':
                return {
                    thumbnail: !!media?.elements?.media?.linkedItems.length
                        ? getMedia(media?.elements?.media?.linkedItems[0])
                        : null,
                    button: getButton(media?.elements?.link.linkedItems[0]),
                    autoplay: !!media?.elements?.autoplay?.value?.length,
                    player_color:
                        media?.elements?.player_color?.value || 'c8102e',
                    disable_controls:
                        !!media?.elements?.disable_controls?.value?.length ||
                        false,
                    caption:
                        isBlank(
                            media?.elements?.media?.linkedItems[0]?.elements
                                ?.caption_v2
                        ) ||
                        media?.elements?.media?.linkedItems[0]?.elements
                            ?.caption?.value ||
                        isBlank(media?.elements?.caption) ||
                        null,
                    caption_alignment:
                        media?.elements?.media?.linkedItems[0]?.elements
                            ?.caption_alignment?.value[0]?.codename ||
                        media?.elements?.caption_alignment?.value[0]
                            ?.codename ||
                        null,
                    type,
                };
            case 'comparison_slider':
                return {
                    images: !!media?.elements?.images?.linkedItems?.length
                        ? media?.elements?.images?.linkedItems.map((image) => {
                              return getMedia(image);
                          })
                        : null,
                    caption: media?.elements?.caption.value,
                    position: media?.elements?.slider_position.value || 50,
                    elevation:
                        media?.elements?.elevation__elevation?.value[0]
                            ?.codename || false,
                    type,
                };
            case 'iframe':
                return {
                    url: media?.elements?.url?.value,
                    type,
                };
            case 'image_link':
                return {
                    title: getRichText(media?.elements?.title),
                    media: getMedia(media?.elements?.media.linkedItems[0]),
                    button: getButton(
                        media?.elements?.link.linkedItems[0],
                        internal_links
                    ),
                    type,
                };
            case 'pdf':
                return {
                    title: getRichText(media?.elements?.title),
                    media:
                        getMedia(media?.elements?.media.linkedItems[0]) || null,
                    button: getButton(media, internal_links),
                    type,
                };
            case 'internal_video':
                return {
                    desktop_video:
                        media?.elements?.desktop_video?.value[0]?.url || null,
                    tablet_video:
                        media?.elements?.tablet_video?.value[0]?.url || null,
                    mobile_video:
                        media?.elements?.mobile_video?.value[0]?.url || null,
                    id: media?.system?.id,
                    type,
                };
            default:
                return null;
        }
    } catch (error) {
        if (error instanceof DeliveryError) {
            console.error(error.message, error.errorCode);
            return null;
        } else {
            console.error(error);
            return null;
        }
    }
};
