import Modal from 'components/Modal';
import useModal from 'lib/hooks/useModal';
import Video from 'components/Media/elements/Video';
import { useEffect } from 'react';

const VideoRedirectBasedPopup = ({ video }) => {
    const { isOpen, setIsOpen } = useModal();
    useEffect(() => {
        setIsOpen(true);
    }, []);
    return (
        <Modal isOpen={isOpen} setIsOpen={setIsOpen}>
            <Video
                video={{ button: { cta_link: video }, disable_controls: false }}
            />
        </Modal>
    );
};
export default VideoRedirectBasedPopup;
