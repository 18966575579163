import Image from 'components/Media/elements/Image';
import { ReactCompareSlider } from 'react-compare-slider';
import Caption from 'components/Caption';
import Handle from './elements/Handle';
import classNames from 'classnames';

const ComparisonSlider = ({ media }) => {
    return (
        <>
            <ReactCompareSlider
                boundsPadding={40}
                className={classNames('comparison', {
                    [`elevation-${media.elevation}`]: media.elevation,
                })}
                handle={<Handle />}
                itemOne={<Image image={media.images[0]} />}
                itemTwo={<Image image={media.images[1]} />}
                position={media.position}
            />
            {media.caption && <Caption caption={media.caption} />}
        </>
    );
};

export default ComparisonSlider;
