import SkipContent from 'components/SkipContent';
import Desktop from './variants/Desktop';
import Mobile from './variants/Mobile';
import classNames from 'classnames';

const Navbar = ({ ...props }) => {
    return (
        <header
            className={classNames('header', {
                'header--sticky': props.sticky,
            })}
        >
            <SkipContent />
            <Desktop {...props} />
            <Mobile {...props} />
        </header>
    );
};

export default Navbar;
