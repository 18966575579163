import classNames from 'classnames';
import Parse from 'react-html-parser';

const Caption = ({ caption, alignment, size }) => {
    return (
        <div
            className={classNames('caption', {
                'caption--left': alignment === 'left',
                'caption--lg': size === 'large',
            })}
        >
            {caption && Parse(caption)}
        </div>
    );
};

export default Caption;
