import dynamic from 'next/dynamic';
import Icon from 'components/Icon';
import classNames from 'classnames';
import Link from 'components/Link';
import parse from 'react-html-parser';

const MotionLi = dynamic(() =>
    import('framer-motion').then((module) => module.motion.li)
);

const AnimatePresence = dynamic(() =>
    import('framer-motion').then((module) => module.AnimatePresence)
);

const LevelTwo = ({
    links,
    levelOne,
    setLevelOne,
    setLevelTwo,
    setActive,
    mobile_menu_back_label,
}) => {
    return (
        <ul className="mobile__list">
            {links?.map((item) => {
                const { links, text, id } = item;
                return (
                    <AnimatePresence key={id}>
                        {levelOne === id && (
                            <MotionLi
                                className="mobile__item"
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                exit={{ opacity: 0 }}
                            >
                                <button
                                    className="mobile__back"
                                    onClick={() => {
                                        setLevelOne(null);
                                    }}
                                >
                                    {mobile_menu_back_label}
                                </button>
                                <h3 className="mobile__link--lg">{text}</h3>
                                <hr className="mobile__seperator" />
                                <ul className="mobile__sublist">
                                    {links?.map((item, index) => {
                                        const { links, id, url } = item;
                                        const text = item.text.replace(
                                            '<br>',
                                            ' '
                                        );
                                        return (
                                            <li
                                                key={index}
                                                className="mobile__item"
                                            >
                                                {links && (
                                                    <button
                                                        className="mobile__link mobile__dropdown mobile__subdropdown"
                                                        onClick={() => {
                                                            setLevelTwo(id);
                                                        }}
                                                    >
                                                        <span>
                                                            {parse(text)}
                                                        </span>
                                                        <Icon
                                                            icon={
                                                                'fa-solid fa-chevron-right'
                                                            }
                                                        />
                                                    </button>
                                                )}

                                                {!links && (
                                                    <Link
                                                        href={url}
                                                        className={classNames(
                                                            'mobile__link mobile__link--link'
                                                        )}
                                                        onClick={() => {
                                                            setActive(false);
                                                            setLevelOne(null);
                                                            setLevelTwo(null);
                                                        }}
                                                    >
                                                        {parse(text)}
                                                    </Link>
                                                )}
                                            </li>
                                        );
                                    })}
                                </ul>
                            </MotionLi>
                        )}
                    </AnimatePresence>
                );
            })}
        </ul>
    );
};

export default LevelTwo;
