import { useEffect, useState } from 'react';

const useClickOutside = (ref, initialValue) => {
    const [isOpen, setIsOpen] = useState(false);
    useEffect(() => {
        const clickedOutside = (e) => {
            if (isOpen && ref.current && !ref.current.contains(e.target)) {
                setIsOpen(false);
            }
        };
        document.addEventListener('mousedown', clickedOutside);
        return () => {
            document.removeEventListener('mousedown', clickedOutside);
        };
    }, [isOpen, ref]);
    return { isOpen, setIsOpen };
};
export default useClickOutside;
