import { useState } from 'react';
import { getSearchResults } from 'lib/utils/getSearch';

const useSearch = (algoliaCalls) => {
    const [data, setData] = useState(null);
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);

    const request = async (searchTerm) => {
        let data = null;
        setLoading(true);
        try {
            if (searchTerm) {
                let results = [];
                for (const algoliaCall of algoliaCalls) {
                    const result = await algoliaCall(searchTerm);
                    results = results.concat(getSearchResults(result.hits));
                }
                data =
                    results && results.length > 0
                        ? results.filter(
                              (item) =>
                                  item.url_slug !== 'http://press-release-1'
                          )
                        : null;
            }
            setData(data);
        } catch (err) {
            setError(err.message || 'Unexpected Error!');
        } finally {
            setLoading(false);
        }
        return data;
    };

    return { data, error, loading, request };
};
export default useSearch;
