import Image from 'next/legacy/image';
import {
    getImageWidth,
    getImageHeight,
    getImagelayout,
} from 'lib/utils/getMedia';
import classNames from 'classnames';
import { kontentImageLoader } from 'lib/utils/getMedia';

const ResponsiveImage = ({ ...props }) => {
    const {
        image,
        className,
        layout,
        objectFit,
        objectPosition,
        priority,
        defaultBackground,
        breakpoint,
    } = props;
    return (
        <div
            className={classNames({
                [`image--${breakpoint}`]: breakpoint,
            })}
        >
            <Image
                className={classNames({
                    [`${className}`]: className,
                    [`bg-${image.bg_color || defaultBackground}`]:
                        image.bg_color || defaultBackground,
                })}
                width={getImageWidth(layout, image, breakpoint)}
                height={getImageHeight(layout, image, breakpoint)}
                layout={getImagelayout(layout, image)}
                objectFit={image?.object_fit || objectFit || 'contain'}
                objectPosition={
                    image?.object_position || objectPosition || 'top'
                }
                alt={image.alt ? image.alt : 'placeholder'}
                src={image[breakpoint]?.src || image.desktop.src}
                loader={kontentImageLoader}
                priority={priority}
            />
        </div>
    );
};
export default ResponsiveImage;
