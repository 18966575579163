import { useEffect, useState } from 'react';
import useWindowDimensions from 'lib/hooks/useWindowDimensions';

const useSetVideo = (video) => {
    const [videoSrc, setVideoSrc] = useState(null);
    const { width } = useWindowDimensions();

    const desktop = 992;
    const tablet = 768;

    const setVideo = () => {
        if (width >= desktop) {
            setVideoSrc(video.desktop_video);
        }
        if (width < desktop && width >= tablet && video.tablet_video) {
            setVideoSrc(video.tablet_video);
        }
        if (width < desktop && width >= tablet && !video.tablet_video) {
            setVideoSrc(video.desktop_video);
        }
        if (width < tablet && video.mobile_video) {
            setVideoSrc(video.mobile_video);
        }
        if (width < tablet && !video.mobile_video) {
            setVideoSrc(video.desktop_video);
        }
    };
    useEffect(() => {
        if (video) {
            setVideo();
        }
    }, [width]);
    return { videoSrc };
};

export default useSetVideo;
