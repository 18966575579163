import Link from 'components/Link';
import Image from 'next/image';

const Brand = ({ logo }) => {
    return (
        <>
            {logo && (
                <Link
                    href="/"
                    className="navbar__brand"
                    style={{ top: logo.position_top }}
                >
                    <Image
                        src={logo.src.desktop.src}
                        width={logo.src.desktop.width}
                        height={logo.src.desktop.height}
                        alt={logo.src.alt}
                        priority
                    />
                </Link>
            )}
        </>
    );
};

export default Brand;
