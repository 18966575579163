import { useEffect, useState } from 'react';
import Brand from './elements/Brand';
import Trigger from './elements/Trigger';
import NavItems from './elements/NavItems';
import useWindowDimensions from 'lib/hooks/useWindowDimensions';

const Mobile = ({
    links,
    remove_search_functionality,
    search_placeholder,
    utility_navigation,
    logo,
    mobile_menu_back_label,
}) => {
    const [active, setActive] = useState(false);
    const [levelOne, setLevelOne] = useState(null);
    const [levelTwo, setLevelTwo] = useState(null);

    const size = useWindowDimensions();
    useEffect(() => {
        if (!active || size.width > 1200) {
            document.body.style.overflow = 'visible';
        } else if (active && size.width < 1200) {
            document.body.style.overflow = 'hidden';
        }
    }, [size, active]);
    const handleToggle = () => {
        if (active) {
            document.body.style.overflowY = 'scroll';
            setLevelOne(null);
            setLevelTwo(null);
        } else {
            document.body.style.overflowY = 'hidden';
        }
        setActive(!active);
    };
    return (
        <div className="mobile">
            <Brand logo={logo} utility_navigation={utility_navigation} />
            <Trigger active={active} handleToggle={handleToggle} />
            {active && (
                <NavItems
                    links={links}
                    setLevelOne={setLevelOne}
                    levelOne={levelOne}
                    setLevelTwo={setLevelTwo}
                    levelTwo={levelTwo}
                    setActive={setActive}
                    utility_navigation={utility_navigation}
                    remove_search_functionality={remove_search_functionality}
                    handleToggle={handleToggle}
                    search_placeholder={search_placeholder}
                    mobile_menu_back_label={mobile_menu_back_label}
                    active={active}
                />
            )}
        </div>
    );
};

export default Mobile;
