import { DeliveryError } from '@kontent-ai/delivery-sdk';

/**
 * IsBlank
 * @param {*} data
 * @returns Rich text value and remove blank content
 */
export const isBlank = (data) => {
    try {
        if (!data) {
            return null;
        }
        return data?.value === '<p><br></p>' ? '' : data.value;
    } catch (error) {
        if (error instanceof DeliveryError) {
            console.error(error.message, error.errorCode);
            return null;
        } else {
            console.error(error);
            return null;
        }
    }
};
