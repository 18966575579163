import { useRouter } from 'next/router';
import { useState, useEffect } from 'react';
import ClickBasedPopup from './elements/ClickBasedPopup';
import LoadBasedPopup from './elements/LoadBasedPopup';
import VideoRedirectBasedPopup from './elements/VideoRedirectBasedPopup';
import PdfRedirectBasedPopup from './elements/PdfPopup';

const Popups = ({ popups }) => {
    const router = useRouter();
    // All click based popups in its own array
    const clickPopups = popups?.filter((popup) => popup.trigger === 'click');

    // All load based popups in its own array
    const loadPopups = popups?.filter((popup) => {
        const pattern = new RegExp(popup.regex);
        if (popup.trigger === 'load' && pattern.test(router.asPath)) {
            return popup;
        }
    });
    // The total count for all load based popups, used additionally for showing load based popups sequentially if there are more than one loading at a time.
    const [count, setCount] = useState(loadPopups.length);

    // Logic for if a video popup needs to happen on load if a user had been redirected from a country site that does not legally support video.
    const videoRedirectPattern = new RegExp('^.*?(video=.*|url=.*www\.youtube\.com.*)$');
    const videoRedirect = videoRedirectPattern.test(router.asPath);

    const pdfRedirectPattern = new RegExp('^.*?url=(\/{2}|(%2F){2})edwardsprod\.blob\.core\.windows\.net.*$');
    const pdfRedirect = pdfRedirectPattern.test(router.asPath);
    useEffect(() => {
        // Ensures that the proper count is set when popups on page change.
        setCount(loadPopups.length);
    },[popups])
    return (
        <>
            {/* Popups that are triggered on page load based on if their urls end with video query param */}
            {videoRedirect && <VideoRedirectBasedPopup video={router.query.video ? router.query.video : router.query.url} />}

            {/* Popups that are triggered on page load based on if their urls end with url query param */}
            {pdfRedirect && <PdfRedirectBasedPopup url={router.query.url} />}
                    
            {/* Popups that are triggered on page load based on if their urls match regex */}
            {loadPopups.map((popup, index) => {
                const order = index + 1;
                return (
                    <LoadBasedPopup
                        key={index}
                        popup={popup}
                        order={order}
                        count={count}
                        setCount={setCount}
                    />
                );
            })}
            {/* Popups that are triggered on link click based on if their href matches regex */}
            {clickPopups.map((popup, index) => {
                return <ClickBasedPopup key={index} popup={popup} />;
            })}
        </>
    );
};

export default Popups;
