import Icon from 'components/Icon';

const Handle = () => {
   return (
      <div className="comparison__wrapper">
         <div className="comparison__handle">
            <div className="comparison__line"></div>
            <div className="comparison__button" role="button">
               <Icon className="comparison__arrows" icon={'fa-solid fa-sort'} />
            </div>
            <div className="comparison__line"></div>
         </div>
      </div>
   );
};

export default Handle;
