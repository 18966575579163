import Button from 'components/Button';
import Icon from 'components/Icon';
import classNames from 'classnames';
import dynamic from 'next/dynamic';
const FocusTrap = dynamic(() => import('focus-trap-react'));
import reactDom from 'react-dom';
import { useEffect, useCallback, useRef } from 'react';
import { useRouter } from 'next/router';

const Modal = ({
    isOpen,
    setIsOpen,
    children,
    modalStyle,
    decline_redirect,
    popup_style = 'warning',
    className,
}) => {
    const modalRef = useRef();
    const router = useRouter();
    const handleClose = (e) => {
        if (modalRef.current === e.target && !decline_redirect) {
            setIsOpen(false);
        }
        if (modalRef.current === e.target && decline_redirect) {
            setIsOpen(false);
            router.push(decline_redirect);
        }
    };
    const closeBtnTrigger = () => {
        if (decline_redirect) {
            router.push(decline_redirect);
        } else {
            setIsOpen(false);
        }
    };
    const keyPress = useCallback(
        (e) => {
            if (e.key === 'Escape' && isOpen) {
                setIsOpen(false);
            }
            if (e.key === 'Escape' && isOpen && decline_redirect) {
                setIsOpen(false);
                router.push(decline_redirect);
            }
        },
        [setIsOpen, isOpen]
    );
    useEffect(() => {
        document.addEventListener('keydown', keyPress);
        return () => document.removeEventListener('keydown', keyPress);
    }, [keyPress]);
    return (
        <>
            {isOpen &&
                reactDom.createPortal(
                    <FocusTrap>
                        <div
                            role="dialog"
                            className={classNames('modal', {
                                [`${className}`]: className,
                            })}
                            aria-modal="true"
                            tabIndex="-1"
                            ref={modalRef}
                            onClick={handleClose}
                        >
                            <div
                                className={classNames('modal__content', {
                                    'modal__content--disable-x':
                                        popup_style === 'basic',
                                    'modal__content--multi':
                                        popup_style === 'multi',
                                })}
                            >
                                <Button
                                    className={classNames('modal__close', {
                                        'modal__close--disable-x':
                                            popup_style === 'basic',
                                    })}
                                    trigger={closeBtnTrigger}
                                >
                                    <Icon icon={'fa-light fa-xmark'} />
                                </Button>

                                <div
                                    className={classNames('modal__body', {
                                        'modal__body--video':
                                            modalStyle === 'video',
                                    })}
                                >
                                    {children}
                                </div>
                            </div>
                        </div>
                    </FocusTrap>,
                    document.getElementById('portal')
                )}
        </>
    );
};

export default Modal;
