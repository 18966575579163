import dynamic from 'next/dynamic';
import classNames from 'classnames';
import Link from 'components/Link';
import parse from 'react-html-parser';

const MotionLi = dynamic(() =>
    import('framer-motion').then((module) => module.motion.li)
);
const AnimatePresence = dynamic(() =>
    import('framer-motion').then((module) => module.AnimatePresence)
);

const LevelThree = ({
    links,
    levelTwo,
    setLevelTwo,
    setLevelOne,
    setActive,
    mobile_menu_back_label,
}) => {
    return (
        <ul className="mobile__list">
            {links?.map((item) => {
                const { links } = item;
                return links?.map((item) => {
                    const { links, id, text, description } = item;
                    return (
                        <AnimatePresence key={id}>
                            {levelTwo === id && (
                                <MotionLi
                                    className="mobile__item"
                                    initial={{ opacity: 0 }}
                                    animate={{ opacity: 1 }}
                                    exit={{ opacity: 0 }}
                                >
                                    <button
                                        className="mobile__back"
                                        onClick={() => {
                                            setLevelTwo(null);
                                        }}
                                    >
                                        {mobile_menu_back_label}
                                    </button>
                                    <h3 className="mobile__link--lg mb-2">
                                        {text}
                                    </h3>
                                    <div className="mobile__description">
                                        {description}
                                    </div>
                                    <hr className="mobile__seperator" />
                                    <ul className="mobile__sublist">
                                        {links &&
                                            links?.map((item, index) => {
                                                const { text, url } = item;
                                                return (
                                                    <li
                                                        className="mobile__item"
                                                        key={index}
                                                    >
                                                        <Link
                                                            href={url}
                                                            className={classNames(
                                                                'mobile__link mobile__link--link'
                                                            )}
                                                            onClick={() => {
                                                                setActive(
                                                                    false
                                                                );
                                                                setLevelOne(
                                                                    null
                                                                );
                                                                setLevelTwo(
                                                                    null
                                                                );
                                                            }}
                                                        >
                                                            {parse(text)}
                                                        </Link>
                                                    </li>
                                                );
                                            })}
                                    </ul>
                                </MotionLi>
                            )}
                        </AnimatePresence>
                    );
                });
            })}
        </ul>
    );
};

export default LevelThree;
