import classNames from 'classnames';
import Image from 'next/legacy/image';
import Link from 'components/Link';
import parse from 'react-html-parser';

const Card = ({ link, active, setActive }) => {
    return (
        <ul
            className={classNames(
                `navbar__dropdown grid-md-${link.links?.length}`,
                {
                    'navbar__dropdown--open': link.id === active,
                    'navbar__dropdown--card': link.style === 'card',
                }
            )}
        >
            {link.links?.map((item, index) => {
                return (
                    <li key={index}>
                        <Link
                            className="navbar__dropdown-link"
                            href={item.url}
                            onClick={() => setActive(null)}
                        >
                            <div className="navbar__icon">
                                {item.icon && (
                                    <Image
                                        className="navbar__icon--normal"
                                        layout="fill"
                                        objectFit="contain"
                                        src={item.icon}
                                    />
                                )}
                                {item.icon && (
                                    <Image
                                        className="navbar__icon--luminosity"
                                        layout="fill"
                                        objectFit="contain"
                                        src={item.icon}
                                    />
                                )}
                            </div>
                            {item.rich_text_title
                                ? parse(
                                      `<div class="navbar__title">${item.rich_text_title}</div>`
                                  )
                                : parse(
                                      `<div class="navbar__title">${item.text}</div>`
                                  )}
                        </Link>
                    </li>
                );
            })}
        </ul>
    );
};

export default Card;
