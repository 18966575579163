/**
 * Footer Block
 * A footer block acts as the footer for the website.
 */

import Footer1a from './variants/Footer1a';

const prefix = 'footer';

const Footer = ({ ...props }) => {
    switch (props.variant) {
        case `${prefix}_1a`:
            return <Footer1a {...props} />;
        default:
            return null;
    }
};

export default Footer;
