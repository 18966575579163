import Link from 'components/Link';

const FooterLink = ({ title, url }) => {
	return (
		<li className='footer__item'>
			<Link href={url} className='footer__link'>
				{title}
			</Link>
		</li>
	);
};

export default FooterLink;
