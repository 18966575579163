import Modal from 'components/Modal';
import useModal from 'lib/hooks/useModal';
import { useEffect } from 'react';

const PdfRedirectBasedPopup = ({ url }) => {
    const { isOpen, setIsOpen } = useModal();
    if (url) {
        if (!url?.includes('http')) {
            url = `https:${url}`;
        }
    }
    useEffect(() => {
        setIsOpen(true);
    }, []);
    return (
        <Modal isOpen={isOpen} setIsOpen={setIsOpen}>
            <iframe src={`${url}`} className="modal__pdf"></iframe>
        </Modal>
    );
};
export default PdfRedirectBasedPopup;
