import Logo from 'assets/images/logo.svg';
import Image from 'next/image';
import MobileLogo from 'assets/images/logo-mobile.svg';

function StandaloneNavbar({ logo_url }) {
    return (
        <>
            {logo_url && (
                <>
                    <div className="navbar">
                        <div className="navbar__container">
                            {logo_url && (
                                <a href={logo_url} className="navbar__brand">
                                    <Image
                                        height={105}
                                        width={80}
                                        src={Logo}
                                        alt="Edwards Lifesciences Logo"
                                        priority
                                    />
                                </a>
                            )}
                        </div>
                    </div>
                    <div className="mobile">
                        {logo_url && (
                            <a href={logo_url} className="mobile__brand">
                                <Image
                                    height={40}
                                    width={139}
                                    src={MobileLogo}
                                    alt="Edwards Lifesciences Logo"
                                    priority
                                />
                            </a>
                        )}
                    </div>
                </>
            )}
        </>
    );
}
export default StandaloneNavbar;
