import Link from 'components/Link';
import classNames from 'classnames';

const SplitCategory = ({ links, split, setActive, line_height }) => {
    return (
        <div>
            <ul
                className={classNames('navbar__category-list', {
                    'navbar__category-list--split': split,
                })}
            >
                {[1, 2].map((number) => (
                    <li key={number} className="navbar__category-item mb-0">
                        <ul className="navbar__category-list">
                            {links &&
                                links?.map((item, index) => {
                                    const {
                                        text,
                                        url,
                                        col,
                                    } = item;
                                    return (
                                        col === number && (
                                            <li
                                                key={index}
                                                className="navbar__category-item"
                                            >
                                                <Link
                                                    href={url}
                                                    className={classNames("navbar__category-link", { "navbar__category-link--28": line_height === 'n28px' })}
                                                    onClick={() =>
                                                        setActive(null)
                                                    }
                                                >
                                                    {text}
                                                </Link>
                                            </li>
                                        )
                                    );
                                })}
                        </ul>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default SplitCategory;
