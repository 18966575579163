import React from 'react';
import PopupBody from '../../Popups/elements/PopupBody';
import { useRouter } from 'next/router';
import { video_redirect } from 'assets/data/data';
import Video from './Video';

/*
When a video is accompanied by a thumbnail or triggered by a button click prior to a popup appearing, a conditional check is performed to determine if the current locale permits viewing the video. If the video is permitted in the current locale, it plays without any restrictions. However, if the video is not allowed, a popup message appears, notifying the viewer that the video is only accessible in a different country, and they are subsequently redirected.
*/

const VideoModalContent = ({ video, setIsOpen, disableModal }) => {
    const { asPath, locale } = useRouter();
    return (
        <>
            {!video_redirect[locale] && <Video video={video} disableModal={disableModal} />}
            {video_redirect[locale] && video_redirect[locale].regex.test(video.button.cta_link) && (
                <PopupBody
                    {...video_redirect[locale]}
                    handleAccept={() => setIsOpen(false)}
                    handleDecline={() => setIsOpen(false)}
                    accept_url={`${asPath.replace(/(#|\?).*$/,'')}?video=${video.button.cta_link}`}
                />
            )}
        </>
    );
};

export default VideoModalContent;
