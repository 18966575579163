import { useEffect, useState } from 'react';

const useModal = () => {
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        isOpen
            ? document.body.classList.add('body--modal-active')
            : document.body.classList.remove('body--modal-active');
    }, [isOpen]);

    return { isOpen, setIsOpen };
};

export default useModal;
