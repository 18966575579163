import Link from 'components/Link';
import { useRouter } from 'next/router';
import { useEffect, useRef, useState } from 'react';
import Icon from 'components/Icon';
import useClickOutside from 'lib/hooks/useClickOutside';
import classNames from 'classnames';

const CountrySelect = ({ countries }) => {
    const { locale } = useRouter();

    const [activeCountry, setActiveCountry] = useState(null);
    const handleClick = () => {
        setIsOpen(!isOpen);
    };

    const ref = useRef();
    const { isOpen, setIsOpen } = useClickOutside(ref);
    useEffect(() => {
        const country = countries.find(
            (country) => country.locale.replace('_', '-') === locale
        );
        setActiveCountry(country);
    }, []);
    return (
        <div className="country" ref={ref}>
            <button onClick={handleClick} className="country__trigger">
                <Icon className="country__globe" icon="fa-light fa-globe" />
                <div className="country__title">
                    {activeCountry && activeCountry.title}
                </div>
                <div className="country__arrow">
                    <Icon
                        wrapperIconClassName={classNames('country__icon', {
                            'country__icon--active': isOpen,
                        })}
                        icon={'fa-solid fa-chevron-down'}
                    />
                </div>
            </button>
            {isOpen && (
                <div className="country__dropdown">
                    {countries.map((country) => {
                        return (
                            <Link
                                key={country.locale}
                                href={country.url}
                                className="country__dropdown-item"
                            >
                                {country.title}
                            </Link>
                        );
                    })}
                </div>
            )}
        </div>
    );
};
export default CountrySelect;
