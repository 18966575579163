import Video from './elements/Video';
import Image from './elements/Image';
import Icon from 'components/Icon';
import ComparisonSlider from 'components/ComparisonSlider.js';
import InternalVideo from './elements/InternalVideo';
import Iframe from './elements/Iframe';
import MediaLink from './elements/MediaLink';

const Media = ({
    className,
    wrapperClassName,
    wrapperIconClassName,
    media,
    layout,
    objectFit,
    objectPosition,
    priority,
    defaultBackground,
    disableModal,
    disableElevation,
    disableCaption,
    icon_size,
    slideChange,
}) => {
    if (!media) {
        return null;
    }
    const { type, id } = media;
    return (
        <>
            {type === 'image' && (
                <Image
                    key={id}
                    image={media}
                    className={className}
                    wrapperClassName={wrapperClassName}
                    layout={layout}
                    objectFit={objectFit}
                    objectPosition={objectPosition}
                    priority={priority}
                    defaultBackground={defaultBackground}
                    disableElevation={disableElevation}
                    disableCaption={disableCaption}
                />
            )}
            {type === 'internal_video' && (
                <InternalVideo
                    key={id}
                    video={media}
                    className={className}
                    wrapperClassName={wrapperClassName}
                />
            )}
            {type === 'video' && (
                <Video
                    video={media}
                    className={className}
                    disableModal={disableModal}
                    disableElevation={disableElevation}
                    disableCaption={disableCaption}
                    slideChange={slideChange}
                />
            )}
            {type === 'icon' && (
                <Icon
                    wrapperIconClassName={wrapperIconClassName}
                    className={className}
                    icon={media.icon}
                    size={icon_size}
                    color={media.color}
                    primary={media.primary}
                    secondary={media.secondary}
                />
            )}
            {(type === 'image_link' || type === 'pdf') && (
                <MediaLink media={media} />
            )}
            {type === 'comparison_slider' && <ComparisonSlider media={media} />}
            {type === 'iframe' && <Iframe media={media} />}
        </>
    );
};

export default Media;
