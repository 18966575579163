/**
 * https://fontawesome.com/v6/docs/web/use-with/react/add-icons#add-individual-icons-explicitly
 * 1) Add import aliases to avoid naming conflicts!
 * 2) Add imported icon to the key object
 */

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import {
    faApple,
    faFacebook,
    faGooglePlay,
    faInstagram,
    faLinkedin,
    faLinkedinIn,
    faWindows,
    faXTwitter,
    faYoutube,
} from '@fortawesome/free-brands-svg-icons';
import {
    faAnglesRight as faAnglesRightRegular,
    faMagnifyingGlass as faMagnifyingGlassRegular,
    faCircleArrowRight as faCircleArrowRightRegular,
    faArrowRight as faArrowRightRegular,
    faFilePdf as faFilePdfRegular,
    faArrowUpRightFromSquare as faArrowUpRightFromSquareRegular,
    faVideo as faVideoRegular,
    faPlus as faPlusRegular,
    faChevronDown as faChevronDownRegular,
    faPrint,
    faDownload as faDownloadRegular,
    faLocationDot as faLocationDotRegular,
    faClock as faClockRegular,
    faHashtag as faHashtagRegular,
    faBarsFilter as faBarsFilterRegular,
    faXmark as faXmarkRegular,
} from '@fortawesome/pro-regular-svg-icons';

import {
    faXmark as faXmarkLight,
    faArrowUpRightFromSquare as faArrowUpRightFromSquareLight,
    faGlobe as faGlobeLight,
    faArrowRight as faArrowRightLight,
    faChevronDown as faChevronDownLight,
} from '@fortawesome/pro-light-svg-icons';
import {
    faArrowUpRightFromSquare as faArrowUpRightFromSquareSolid,
    faAnglesRight as faAnglesRightSolid,
    faArrowRight as faArrowRightSolid,
    faArrowLeft as faArrowLeftSolid,
    faBookOpen as faBookOpenSolid,
    faCaretRight as faCaretRightSolid,
    faChevronDown as faChevronDownSolid,
    faChevronRight as faChevronRightSolid,
    faChevronsRight as faChevronsRightSolid,
    faDownload as faDownloadSolid,
    faFileLines as faFileLinesSolid,
    faFilePdf as faFilePdfSolid,
    faMemo as faMemoSolid,
    faUpRightFromSquare as faUpRightFromSquareSolid,
    faVideo as faVideoSolid,
    faAngleDown as faAngleDownSolid,
    faChevronLeft as faChevronLeftSolid,
    faGlobe as faGlobeSolid,
    faPhone as faPhoneSolid,
    faDiamondTurnRight as faDiamondTurnRightSolid,
    faRoad as faRoadSolid,
    faPlay as faPlaySolid,
    faPause as faPauseSolid,
    faMagnifyingGlass as faMagnifyingGlassSolid,
    faCircleXmark as faCircleXmarkSolid,
    faSort as faSortSolid,
    faChevronUp as faChevronUpSolid,
    faCheck as faCheckSolid,
    faXmark as faXmarkSolid,
    faTriangleExclamation as faTriangleExclamationSolid,
    faClose as faCloseSolid,
    faHospital as faHospitalSolid,
    faEnvelope as faEnvelopeSolid,
} from '@fortawesome/pro-solid-svg-icons';

import {
    faArrowRight as faArrowRightThin,
    faBookOpen as faBookOpenThin,
    faHandshake as faHandshakeThin,
    faSuitcase as faSuitcaseThin,
    faUsers as faUsersThin,
    faEnvelope as faEnvelopeThin,
    faMobileNotch as faMobileNotchThin,
    faMapLocationDot as faMapLocationDotThin,
} from '@fortawesome/pro-thin-svg-icons';

import { faCirclePlay as faCirclePlayDuoTone } from '@fortawesome/pro-duotone-svg-icons';

const key = {
    'fa-thin': {
        'fa-mobile-notch': faMobileNotchThin,
        'fa-arrow-right': faArrowRightThin,
        'fa-book-open': faBookOpenThin,
        'fa-handshake': faHandshakeThin,
        'fa-suit-case': faSuitcaseThin,
        'fa-users': faUsersThin,
        'fa-envelope': faEnvelopeThin,
        'fa-map-location-dot': faMapLocationDotThin,
    },
    'fa-brands': {
        'fa-apple': faApple,
        'fa-facebook': faFacebook,
        'fa-google-play': faGooglePlay,
        'fa-instagram': faInstagram,
        'fa-linkedin-in': faLinkedinIn,
        'fa-linkedin': faLinkedin,
        'fa-windows': faWindows,
        'fa-x-twitter': faXTwitter,
        'fa-twitter': faXTwitter,
        'fa-youtube': faYoutube,
    },
    'fa-regular': {
        'fa-angles-right': faAnglesRightRegular,
        'fa-magnifying-glass': faMagnifyingGlassRegular,
        'fa-circle-arrow-right': faCircleArrowRightRegular,
        'fa-arrow-right': faArrowRightRegular,
        'fa-chevron-down': faChevronDownRegular,
        'fa-file-pdf': faFilePdfRegular,
        'fa-arrow-up-right-from-square': faArrowUpRightFromSquareRegular,
        'fa-video': faVideoRegular,
        'fa-plus': faPlusRegular,
        'fa-print': faPrint,
        'fa-download': faDownloadRegular,
        'fa-location-dot': faLocationDotRegular,
        'fa-clock': faClockRegular,
        'fa-hashtag': faHashtagRegular,
        'fa-bars-filter': faBarsFilterRegular,
        'fa-xmark': faXmarkRegular,
    },
    'fa-light': {
        'fa-arrow-right': faArrowRightLight,
        'fa-arrow-up-right-from-square': faArrowUpRightFromSquareLight,
        'fa-xmark': faXmarkLight,
        'fa-globe': faGlobeLight,
        'fa-chevron-down': faChevronDownLight,
    },
    'fa-solid': {
        'fa-download': faDownloadSolid,
        'fa-angle-down': faAngleDownSolid,
        'fa-angles-right': faAnglesRightSolid,
        'fa-arrow-left': faArrowLeftSolid,
        'fa-arrow-right': faArrowRightSolid,
        'fa-arrow-up-right-from-square': faArrowUpRightFromSquareSolid,
        'fa-book-open': faBookOpenSolid,
        'fa-caret-right': faCaretRightSolid,
        'fa-chevron-down': faChevronDownSolid,
        'fa-chevron-up': faChevronUpSolid,
        'fa-chevron-left': faChevronLeftSolid,
        'fa-chevron-right': faChevronRightSolid,
        'fa-chevrons-right': faChevronsRightSolid,
        'fa-download': faDownloadSolid,
        'fa-file-lines': faFileLinesSolid,
        'fa-file-pdf': faFilePdfSolid,
        'fa-memo': faMemoSolid,
        'fa-up-right-from-square': faUpRightFromSquareSolid,
        'fa-video': faVideoSolid,
        'fa-phone': faPhoneSolid,
        'fa-globe': faGlobeSolid,
        'fa-road': faRoadSolid,
        'fa-diamond-turn-right': faDiamondTurnRightSolid,
        'fa-play': faPlaySolid,
        'fa-pause': faPauseSolid,
        'fa-magnifying-glass': faMagnifyingGlassSolid,
        'fa-circle-xmark': faCircleXmarkSolid,
        'fa-sort': faSortSolid,
        'fa-check': faCheckSolid,
        'fa-xmark': faXmarkSolid,
        'fa-triangle-exclamation': faTriangleExclamationSolid,
        'fa-close': faCloseSolid,
        'fa-hospital': faHospitalSolid,
        'fa-envelope': faEnvelopeSolid,
    },
    'fa-duotone': {
        'fa-circle-play': faCirclePlayDuoTone,
    },
};

const Icon = ({ className, wrapperIconClassName, icon, size, color, alt }) => {
    // Check if the icon is a string and exists
    if (!icon || typeof icon !== 'string') return null;

    // Split the string into an array "fa-solid fa-arrow-right" => ['fa-solid','fa-arrow-right'];
    const iconArray = icon.split(' ');

    // Return if string does not match
    if (iconArray.length !== 2) return null;

    // Check that the icon exists within the key
    const iconData = key[iconArray[0]]?.[iconArray[1]]
        ? key[iconArray[0]]?.[iconArray[1]]
        : null;

    return (
        <>
            {iconData ? (
                <span
                    className={classNames({
                        [`${wrapperIconClassName}`]: wrapperIconClassName,
                    })}
                >
                    <FontAwesomeIcon
                        icon={iconData}
                        className={classNames({
                            [`${className}`]: className,
                            [`fa-${size}`]: size,
                            [`text-${color}`]: color,
                        })}
                        alt={alt}
                    />
                </span>
            ) : null}
        </>
    );
};
export default Icon;
