import { DeliveryError } from '@kontent-ai/delivery-sdk';
import { isBlank } from './isBlank';
import { getMedia } from './getMedia';

export const getRichText = (data, page) => {
    try {
        const getValue = (data, page) => {
            if (!data) return null;
            const language = page?.system?.language || 'default';
            let value = data?.value;
            if (language === 'fr') {
                value = value
                    .replaceAll('«', '<span class="d-inline">« ')
                    .replaceAll('»', '»</span>');
            }
            if (value?.startsWith('<p><br></p>')) {
                value = null;
            }
            return value;
        };
        return {
            links: data?.links || null,
            value: getValue(data, page),
            components: !!data?.linkedItems?.length
                ? data?.linkedItems?.map((component) => {
                      const type = component?.system?.type;
                      const codename = component?.system?.codename;
                      switch (type) {
                          case 'list':
                              return {
                                  list: component?.elements?.list?.linkedItems?.map(
                                      (item) => {
                                          return {
                                              media:
                                                  getMedia(
                                                      item?.elements?.media
                                                          .linkedItems[0]
                                                  ) || null,
                                              description:
                                                  item?.elements?.description?.value
                                                      .replace('<p>', '')
                                                      .replace('</p>', '') ||
                                                  null,
                                              separator:
                                                  item?.elements?.separator
                                                      ?.value || null,
                                          };
                                      }
                                  ),
                                  variant:
                                      component?.elements?.variant?.value[0]
                                          ?.codename,
                                  split: component?.elements?.split?.value
                                      ?.length,
                                  type,
                                  codename,
                              };
                          case 'text':
                              const inlineTheme = !!component?.elements
                                  ?.inline_theme?.value.length
                                  ? `<span class="text-${component?.elements?.inline_theme?.value[0]?.codename}">`
                                  : `<span>`;
                              return {
                                  element:
                                      component?.elements?.element?.value[0]
                                          ?.codename || null,
                                  theme:
                                      component?.elements?.theme?.value[0]
                                          ?.codename || null,
                                  horizontal_alignment:
                                      component?.elements?.horizontal_alignment
                                          ?.value[0]?.codename || 'left',
                                  content:
                                      component?.elements?.content?.value
                                          .replace('<p>', '')
                                          .replace('</p>', '')
                                          .replaceAll('code', 'span')
                                          .replaceAll(
                                              '<span>',
                                              `${inlineTheme}`
                                          ) || null,
                                  type,
                                  codename,
                              };
                          case 'testimonial':
                              return {
                                  quote:
                                      isBlank(component?.elements?.quote) ||
                                      null,
                                  cite:
                                      isBlank(component?.elements?.cite) ||
                                      null,
                                  tag: component?.elements?.quote?.value.includes(
                                      '<h3>'
                                  )
                                      ? 'h3'
                                      : 'h4',
                                  disable_quotes:
                                      !!component?.elements?.disable_quotes
                                          ?.value?.length || false,
                                  type,
                                  codename,
                              };
                          case 'metric':
                              return {
                                  metric:
                                      isBlank(component?.elements?.metric) ||
                                      null,
                                  description:
                                      isBlank(
                                          component?.elements?.description
                                      ) || null,
                                  type,
                                  codename,
                              };
                          default:
                              return null;
                      }
                  })
                : null,
        };
    } catch (error) {
        if (error instanceof DeliveryError) {
            console.error(error.message, error.errorCode);
            return null;
        } else {
            console.error(error);
            return null;
        }
    }
};
