import Modal from 'components/Modal';
import useModal from 'lib/hooks/useModal';
import { useEffect } from 'react';
import { useRouter } from 'next/router';
import PopupBody from './PopupBody';

const LoadBasedPopup = ({ popup, order, count, setCount }) => {
    const { isOpen, setIsOpen } = useModal();
    const router = useRouter();

    useEffect(() => {
        const { local_storage, expiration_type } = popup;

        if (expiration_type === 'time') {
            // Check if the local storage item is already present
            const local_storage_item = localStorage.getItem(local_storage);

            // If local storage is not present then open the popup
            if (!local_storage_item) {
                return setIsOpen(true);
            }

            const dateOfExpiration = new Date(
                Date.parse(JSON.parse(local_storage_item).expires)
            );

            // If the local storage item has expired then remove the current item from local storage and trigger popup again
            if (dateOfExpiration < new Date()) {
                localStorage.removeItem(local_storage);
                return setIsOpen(true);
            }
        }
        if (expiration_type === 'session') {
            const session_storage_item = sessionStorage.getItem(local_storage);
            // If session storage is not present then open the popup
            if (!session_storage_item) {
                return setIsOpen(true);
            }
        }
    }, [router]);

    useEffect(() => {
        if (!isOpen && !window.location.hash) {
            setTimeout(() => {
                if (window.scrollY > 0) {
                    window.scrollTo({ top: 0 });
                }
            }, 0);
        }
    }, [isOpen]);

    const handleDecline = () => {
        if (popup.decline_redirect) {
            router.push(popup.decline_redirect);
            setIsOpen(false);
        } else {
            setIsOpen(false);
        }
    };

    const handleAccept = () => {
        if (popup.local_storage && popup.expiration_type === 'time') {
            const expirationDate = new Date(
                new Date().getTime() + popup.expiration_time
            ).toISOString();
            localStorage.setItem(
                popup.local_storage,
                JSON.stringify({
                    value: true,
                    expires: expirationDate,
                })
            );
        }
        if (popup.local_storage && popup.expiration_type === 'session') {
            sessionStorage.setItem(popup.local_storage, 'true');
        }
        setCount((prev) => prev - 1);
        setIsOpen(false);
    };
    return (
        order === count && (
            <Modal isOpen={isOpen} setIsOpen={setIsOpen} {...popup}>
                <PopupBody
                    {...popup}
                    handleAccept={handleAccept}
                    handleDecline={handleDecline}
                />
            </Modal>
        )
    );
};
export default LoadBasedPopup;
