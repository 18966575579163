import NormalCategory from './NormalCategory';
import SplitCategory from './SplitCategory';

const Category = ({ item, setActive }) => {
   const { text, description, split } = item;
   return (
      <div className="navbar__category">
         <h4 className="navbar__title">{text}</h4>
         <div className="navbar__description">{description}</div>
         {!split && <NormalCategory {...item} setActive={setActive} />}
         {split && <SplitCategory {...item} setActive={setActive} />}
      </div>
   );
};

export default Category;
