import Link from 'components/Link';
import Image from 'next/image';

const Brand = ({ logo, utility_navigation }) => {
    return (
        <>
            {logo && (
                <Link href="/" className="mobile__brand">
                    <Image
                        src={logo.src.mobile.src}
                        width={logo.src.mobile.width}
                        height={logo.src.mobile.height}
                        alt={logo.src.alt}
                        priority
                    />
                </Link>
            )}
            {/* If no main navigation is present attempt to fallback to utility navigation logo */}
            {!logo && utility_navigation?.logo && (
                <Link href="/" className="mobile__brand">
                    <Image
                        src={utility_navigation.logo.mobile.src}
                        width={utility_navigation.logo.mobile.width}
                        height={utility_navigation.logo.mobile.height}
                        alt={utility_navigation.logo.alt}
                        priority
                    />
                </Link>
            )}
        </>
    );
};

export default Brand;
