import OneTrust from 'blocks/Footer/elements/OneTrust';
import Link from 'components/Link';
import Image from 'next/image';

function StandaloneFooter({
    copyright,
    footer_links,
    cookie_preferences,
    personal_information,
    onetrust_domain,
    footer_logo,
}) {
    return (
        <footer className="footer footer--dark">
            <div className="container">
                <div className="footer__bottom">
                    <div className="footer__bottom-content">
                        {copyright && (
                            <div className="footer__bottom-text">
                                &copy; {new Date().getFullYear()} {copyright}
                            </div>
                        )}
                        <div className="footer__bottom-links">
                            {footer_links?.map((links, index) => {
                                const { text, href } = links;
                                return (
                                    <a
                                        href={href}
                                        key={index}
                                        className="footer__bottom-link"
                                    >
                                        {text}
                                    </a>
                                );
                            })}
                            <OneTrust
                                cookie_preferences={cookie_preferences}
                                personal_information={personal_information}
                                onetrust_domain={onetrust_domain}
                            />
                        </div>
                    </div>
                    {footer_logo && (
                        <div>
                            <Link href={footer_logo?.href}>
                                <Image
                                    src={footer_logo?.src}
                                    alt="logo"
                                    width={footer_logo?.width}
                                    height={footer_logo?.height}
                                />
                            </Link>
                        </div>
                    )}
                </div>
            </div>
        </footer>
    );
}
export default StandaloneFooter;
