import { DeliveryError } from '@kontent-ai/delivery-sdk';

export const getButton = (button, internal_links) => {
    try {
        if (!button) {
            return null;
        }
        let type = button?.system?.type || null;

        const buttonBase = {
            cta_link: button?.elements?.url?.value || null,
            cta_text: button?.elements?.text?.value || null,
            icon:
                button?.elements?.fa_icon__icon?.value[0]?.codename
                    .split('__')
                    .join(' ')
                    .replaceAll('_', '-') ||
                button?.elements?.icon?.value ||
                null, // Icons are imported in as "fa_type__fa_icon"
            icon_color:
                button?.elements?.icon_color?.value[0]?.codename || 'white',

            icon_position:
                button?.elements?.icon_position?.value[0]?.codename || 'right',
            btn_style:
                button?.elements?.button_style?.value[0]?.codename || null,
            btn_size: button?.elements?.button_size?.value[0]?.codename || null,
            target: button?.elements?.target?.value?.length ? true : false,
            operating_system:
                button?.elements?.operating_system?.value[0]?.codename || null,
            codename: button?.system?.codename || null,
            id: button?.system?.id || null,
        };
        if (type === 'link') {
            return buttonBase;
        }
        if (type === 'internal_link') {
            const internal_link = internal_links?.find(
                (internal_link) =>
                    internal_link.codename === button.system.codename
            );
            let data = {
                ...buttonBase,
                cta_link: internal_link?.cta_link || '!#',
            };

            return data;
        }
        if (type === 'pdf') {
            let data = {
                ...buttonBase,
                cta_link: button?.elements?.pdf?.value[0]?.url || null,
                cta_text: button?.elements?.label?.value || null,
            };
            return data;
        }

        if (type === 'button') {
            let data = {
                ...buttonBase,
                cta_link: null,
                cta_text: button?.elements?.label?.value || 'Button',
                trigger: button?.elements?.action?.value[0]?.codename || null,
            };
            return data;
        }
    } catch (error) {
        if (error instanceof DeliveryError) {
            console.error(error.message, error.errorCode);
            return null;
        } else {
            console.error(error);
            return null;
        }
    }
};
