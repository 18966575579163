import parse from 'react-html-parser';

const Metric = ({ metric, description }) => {
    if (!metric) return null;
    return (
        <div className="metric">
            {metric && <div className="metric__metric">{parse(metric)}</div>}
            {description && (
                <div className="metric__description">{parse(description)}</div>
            )}
        </div>
    );
};
export default Metric;
