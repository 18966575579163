import NextScript from 'next/script';

const Script = ({ script_id, type, strategy, content }) => {
  switch (type) {
    case 'inline':
      return <NextScript
        id={script_id}
        strategy={strategy}
      >{`${content}`}</NextScript>;
    case 'src':
      return <NextScript
        id={script_id}
        strategy={strategy}
        src={content}
      />;
    case 'script':
      return <NextScript
        id={script_id}
        strategy={strategy}
        dangerouslySetInnerHTML={{
          __html: content,
        }}
      />;
    default:
      return null;
  }
}

export default Script;
