import classNames from 'classnames';
import useSetVideo from '../hooks/useSetVideo';

const InternalVideo = ({ video, className, wrapperClassName }) => {
    const { videoSrc } = useSetVideo(video);
    return (
        <div
            className={classNames({
                [`${wrapperClassName}`]: wrapperClassName,
            })}
        >
            <video
                key={videoSrc}
                autoPlay
                muted
                loop
                playsInline
                className={classNames('fluid', {
                    [`${className}`]: className,
                })}
            >
                <source
                    src={videoSrc ? videoSrc : video.desktop_video}
                    type="video/mp4"
                />
                Your browser does not support the video tag.
            </video>
        </div>
    );
};

export default InternalVideo;
