import { useEffect, useState } from 'react';
import ccpa from 'assets/images/ccpa.svg';
import Image from 'next/image';

const OneTrust = ({
    cookie_preferences,
    personal_information,
    onetrust_domain,
}) => {
    return (
        <>
            {cookie_preferences && (
                <button
                    className="footer__bottom-link"
                    onClick={(e) => {
                        e.preventDefault();
                        window.Optanon.ToggleInfoDisplay();
                    }}
                >
                    {cookie_preferences}
                </button>
            )}
            {personal_information && (
                <a
                    className="footer__bottom-link footer__bottom-link--with-icon"
                    href={`https://privacyportal.onetrust.com/webform/962ac62d-1abf-489d-8495-ff7a33e224e7/24068fc7-e075-4f86-be67-7e0e7f31656f?src=${onetrust_domain}`}
                >
                    {personal_information}
                    <Image src={ccpa} alt="CCPA Opt-Out Icon" />
                </a>
            )}
        </>
    );
};

export default OneTrust;
