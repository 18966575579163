import classNames from 'classnames';
import Button from 'components/Button';
import Image from 'next/legacy/image';
import Link from 'components/Link';
import { navTitles } from 'lib/utils/navTitles';

const Simple = ({ link, active, setActive }) => {
    return (
        <ul
            className={classNames(
                `navbar__dropdown grid-md-${link.links?.length}`,
                {
                    'navbar__dropdown--open': link.id === active,
                    'navbar__dropdown--simple': link.style === 'simple',
                }
            )}
        >
            {link.links?.map((item, index) => {
                return (
                    <li className="navbar__dropdown-item" key={index}>
                        <Link
                            className="navbar__dropdown-link"
                            href={item.url}
                            onClick={() => setActive(null)}
                        >
                            <div className="navbar__icon">
                                {item.icon && (
                                    <Image
                                        className="navbar__icon--normal"
                                        layout="fill"
                                        objectFit="contain"
                                        src={item.icon}
                                    />
                                )}
                                {item.icon && (
                                    <Image
                                        className="navbar__icon--luminosity"
                                        layout="fill"
                                        objectFit="contain"
                                        src={item.icon}
                                    />
                                )}
                            </div>
                            <h4
                                className="navbar__title"
                                dangerouslySetInnerHTML={{
                                    __html: navTitles(item.text),
                                }} // returns back text formatted with correct breakpoints
                            />
                            {item.cta_text && (
                                <Button
                                    className={'navbar__dropdown-btn'}
                                    btn_style="tertiary"
                                    element_type="div"
                                    btn_size="sm"
                                    block
                                >
                                    <span
                                        dangerouslySetInnerHTML={{
                                            __html: item.cta_text,
                                        }}
                                    />
                                </Button>
                            )}
                        </Link>
                    </li>
                );
            })}
        </ul>
    );
};

export default Simple;
