import classNames from 'classnames';
import Category from './elements/Category';

const Complex = ({ link, active, setActive }) => {
  const catCols = link.links?.map(link => { return link.col });
  const cols = [...new Set(catCols)];
   return (
      <ul
         className={classNames(`navbar__dropdown grid-md-${cols.length || 3}`, {
            'navbar__dropdown--open': link.id === active,
            'navbar__dropdown--complex': link.style === 'complex',
         })}
      >
         {cols.map((number) => (
            <li key={number}>
               {link.links?.map((item, index) => {
                  const { col } = item;
                  return (
                     col === number && (
                        <Category
                           key={index}
                           item={item}
                           setActive={setActive}
                        />
                     )
                  );
               })}
            </li>
         ))}
      </ul>
   );
};

export default Complex;
