import dynamic from 'next/dynamic';
import classNames from 'classnames';
import Icon from 'components/Icon';
const MotionLi = dynamic(() =>
    import('framer-motion').then((module) => module.motion.li)
);
import Link from 'components/Link';
import Button from 'components/Button';
import parse from 'react-html-parser';

const LevelOne = ({ links, setLevelOne, setActive, utility_navigation }) => {
    return (
        <ul className="mobile__list">
            {links?.map((item) => {
                const { links, style, text, url, id, type } = item;

                return (
                    <MotionLi
                        className="mobile__item"
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                    >
                        {type === 'menu_category' && (
                            <button
                                className={classNames(
                                    'mobile__link mobile__dropdown',
                                    {
                                        'mobile__link--lg':
                                            style === 'simple' ||
                                            style === 'complex',
                                        'mobile__link--link':
                                            style === 'basic' && !links?.length,
                                    }
                                )}
                                onClick={() => {
                                    setLevelOne(id);
                                }}
                            >
                                <span>{parse(text)}</span>
                                <Icon icon={'fa-solid fa-chevron-right'} />
                            </button>
                        )}
                        {type === 'page' && (
                            <Link
                                href={url}
                                className={classNames(
                                    'mobile__link mobile__link--link'
                                )}
                                onClick={() => {
                                    setActive(false);
                                    setLevelOne(null);
                                }}
                            >
                                {parse(text)}
                            </Link>
                        )}
                    </MotionLi>
                );
            })}
            <MotionLi
                className="mobile__item mobile__item--util"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
            >
                {utility_navigation?.links?.map((link) => {
                    return (
                        <Button
                            {...link}
                            btn_size="md"
                            onClick={() => {
                                setActive(false);
                                setLevelOne(null);
                            }}
                            key={link.id}
                        >
                            {link.cta_text}
                        </Button>
                    );
                })}
            </MotionLi>
        </ul>
    );
};

export default LevelOne;
