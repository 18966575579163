import Parse, { processNodes, convertNodeToElement, } from 'react-html-parser';
import Link from 'next/link';
import { getUrl } from 'lib/utils/getUrl';
import classNames from 'classnames';
import List from 'components/List';
import Text from 'components/Text';
import Testimonial from 'components/Testimonial';
import Metric from 'components/Metric';

const RichText = ({ className, data }) => {
    function transform(node, index) {
        // transform anchor tag with next/link for internal links starting with '/'
        if (
            node.type === 'tag' &&
            node.name === 'a' &&
            node.attribs.href?.startsWith('/')
        ) {
            return (
                <Link key={index} href={getUrl(node.attribs.href)}>
                    {node.children[0]?.data
                        ? node.children[0]?.data
                        : node.children[0]?.children[0]
                        ? node.children[0]?.children[0]?.data
                        : node.attribs.href}
                </Link>
            );
        }
        // table should be wrapped with extra div to control overflow
        if (node.type === 'tag' && node.name === 'table') {
            return (
                <div className="richtext__table" key={index}>
                    <table
                        border={node.attribs.border}
                        cellPadding={node.attribs.cellpadding}
                        cellSpacing={node.attribs.cellspacing}
                        className={node.attribs.class}
                    >
                        {processNodes(node.children, transform)}
                    </table>
                </div>
            );
        }
        // image formatting changes
        if (node.type === 'tag' && node.name === 'img') {
            return (
                <div className="richtext__image">
                    {convertNodeToElement(node, index, transform)}
                </div>
            );
        }
        //Components;
        if (node.type === 'tag' && node.name === 'object') {
            const codename = node.attribs['data-codename'];
            const component = data?.components?.find(
                (component) => component?.codename === codename
            );
            const type = component?.type;
            if (!type) {
                return null;
            }
            switch (type) {
                case 'list':
                    return <List key={index} {...component} />;
                case 'text':
                    return <Text key={index} {...component} />;
                case 'testimonial':
                    return <Testimonial key={index} {...component} />;
                case 'metric':
                    return <Metric key={index} {...component} />;
                default:
                    return null;
            }
        }

        //Linking URLs of components dynamically
        if (
            node.type === 'tag' &&
            node.name === 'a' &&
            node.attribs['data-item-id']
        ) {
            data?.links?.forEach((url) => {
                if (url?.linkId === node.attribs['data-item-id']) {
                    node.attribs.href = url.urlSlug;
                }
            });

            return (
                <Link key={index} href={getUrl(node.attribs.href)}>
                    {node.children[0]?.data
                        ? node.children[0]?.data
                        : node.children[0]?.children[0]
                        ? node.children[0]?.children[0]?.data
                        : node.attribs.href}
                </Link>
            );
        }
    }

    const options = {
        decodeEntities: true,
        transform,
    };

    return (
        <div
            className={classNames('richtext', { [`${className}`]: className })}
        >
            {Parse(data.value, options)}
        </div>
    );
};

export default RichText;
